import Text from '@sats-group/fresh-fitness-ui-lib/react/text';
import React from 'react';

import Image from 'root/client/components/image/image';
import Tag from 'root/client/components/tag/tag';
import Tags from 'root/client/components/tags/tags';

import type { ProfileCard as Props } from './profile-card.types';

const ProfileCard: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  center,
  expertise,
  expertiseHeader,
  name,
  url,
  image,
  levelTag,
}) => (
  <a className="profile-card" href={url} title={name}>
    <div>
      {image ? (
        <div className="profile-card__image">
          <Image aspectRatio={Image.aspectRatios.Square} {...image} />
        </div>
      ) : null}
      <div className="profile-card__info">
        <div>
          <div className="profile-card__info__row">
            <div className="profile-card__info__name">
              <Text
                elementName="div"
                size={Text.sizes.large}
                theme={Text.themes.emphasis}
              >
                {name}
              </Text>
            </div>
            <Tag text={levelTag} />
          </div>
          {center ? (
            <div className="profile-card__info__club">
              <Text elementName="div" size={Text.sizes.basic}>
                {center}
              </Text>
            </div>
          ) : null}
        </div>
      </div>
    </div>
    <div className="profile-card__expertise">
      <Text size={Text.sizes.basic} theme={Text.themes.medium}>
        {expertiseHeader}
      </Text>
      <div className="profile-card__expertise-tags">
        <Tags
          tags={expertise.map(expertise => ({
            text: expertise,
            theme: 'secondary',
          }))}
        />
      </div>
    </div>
  </a>
);

export default ProfileCard;
