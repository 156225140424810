import React from 'react';
import Button from '@sats-group/fresh-fitness-ui-lib/react/button';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';

import ContentContainer from 'root/client/components/content-container/content-container';
import HiddenInput from 'root/client/components/hidden-input/hidden-input';
import Logo from 'root/client/components/logo/logo';

import type { WaitingArea as Props } from './waiting-area.types';

const WaitingArea: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  form,
  logo,
  translation,
}) => (
  <div className="waiting-area">
    <ContentContainer>
      <div className="waiting-area__content">
        <Logo {...logo} />
        <Text>{translation.text}</Text>
        <form action={form.action} method="post">
          {form.hiddenInputs.map(input => (
            <HiddenInput key={input.name} {...input} />
          ))}
          <Button text={translation.submit} type="submit" />
        </form>
      </div>
    </ContentContainer>
  </div>
);

export default WaitingArea;
