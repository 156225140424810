import cn from 'classnames';
import * as React from 'react';
import LinkButton from '@sats-group/fresh-fitness-ui-lib/react/link-button';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';

import { type CTABanner as Props } from './cta-banner.types';

const themes = {
  true: {
    true: LinkButton.themes.ctaClean,
    false: LinkButton.themes.cta,
  },
  false: {
    true: LinkButton.themes.ctaSecondaryClean,
    false: LinkButton.themes.ctaSecondary,
  },
};

const CTABanner: React.FC<Props> = ({
  featured = false,
  link,
  primary = false,
  text,
  title,
}) => (
  <div
    className={cn('cta-banner', {
      'cta-banner--featured': featured,
    })}
  >
    <div className="cta-banner__content">
      <div className="cta-banner__text">
        <Text
          size={Text.sizes.header3}
          theme={Text.themes.emphasis}
          variant={Text.variants.hero}
        >
          {title}
        </Text>
        <Text>{text}</Text>
      </div>
      <div>
        <LinkButton
          {...link}
          size={LinkButton.sizes.large}
          theme={themes[`${primary}`][`${featured}`]}
        />
      </div>
    </div>
  </div>
);

export default CTABanner;
