import CloseIcon from '@sats-group/icons/18/close';
import React from 'react';
import Button from '@sats-group/fresh-fitness-ui-lib/react/button';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';

import TabTrapper from 'root/client/components/tab-trapper/tab-trapper';
import useEscape from 'root/client/hooks/use-escape';
import useIsMounted from 'root/client/hooks/use-is-mounted';

import type { Modal as Props } from './modal.types';

const Modal: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  closeButtonText,
  hide = () => {},
  isVisible,
  title,
}) => {
  const isMounted = useIsMounted();

  useEscape(hide);

  // NOTE: It's really important that the first render in the client matches the server HTML (`null`). Otherwise ReactDOM.hydrate messes up the HTML.
  if (!isMounted || !isVisible) {
    return null;
  }

  return (
    <TabTrapper isActive={true}>
      <div className="modal">
        <div className="modal__inner">
          <div className="modal__nav">
            <div className="modal__title">
              <Text
                size={Text.sizes.header2}
                theme={Text.themes.emphasis}
                variant={Text.variants.content}
              >
                {title}
              </Text>
            </div>
            <div className="modal__close">
              <Button
                icon={<CloseIcon />}
                iconOnly
                onClick={hide}
                size={Button.sizes.small}
                text={closeButtonText}
                theme={Button.themes.secondary}
              />
            </div>
          </div>
          <div className="modal__children">{children}</div>
          <div aria-hidden className="modal__actions">
            {/* NOTE: This hidden `__actions` element is the exact same size as the floating one,
            acting as a spacer so all the content can be visible when scrolling down */}
            <Button
              disabled
              text={closeButtonText}
              theme={Button.themes.secondary}
              wide
            />
          </div>
          <div className="modal__actions modal__actions--floating">
            <Button
              onClick={hide}
              text={closeButtonText}
              theme={Button.themes.secondary}
              wide
            />
          </div>
        </div>
        <div className="modal__background" onClick={hide} />
      </div>
    </TabTrapper>
  );
};

export default Modal;
