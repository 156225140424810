import Forward18 from '@sats-group/icons/18/forward';
import React from 'react';
import LinkButton from '@sats-group/fresh-fitness-ui-lib/react/link-button';

import ArticleCard from '../article-card/article-card';

import type { ArticleList as Props } from './article-list.types';

const ArticleList: React.FunctionComponent<Props> = ({ actions, cards }) => (
  <div className="article-list">
    <div className="article-list__content">
      <div className="article-list__items">
        {cards.map(card => (
          <div className="article-list__item" key={card.href}>
            <ArticleCard {...card} />
          </div>
        ))}
      </div>
      {actions.length ? (
        <div className="article-list__actions">
          {actions.map(action => (
            <div key={action.href}>
              <LinkButton
                {...action}
                icon={<Forward18 />}
                iconPosition="right"
                theme={LinkButton.themes.secondary}
                wide
              />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  </div>
);

export default ArticleList;
