import { useEffect } from 'react';

import type {
  EmbeddedService,
  PreChatFields,
} from '../types/salesforce-bootstrap-embedded-service';

type Init = Parameters<EmbeddedService['init']>;
type Settings = EmbeddedService['settings'];

const initEmbeddedMessaging = (
  init: Init,
  settings: Settings,
  prechat: PreChatFields,
  postInit: () => void,
) => {
  try {
    if (!window.embeddedservice_bootstrap) {
      return;
    }

    window.embeddedservice_bootstrap.settings.language = settings.language;

    window.addEventListener('onEmbeddedMessagingReady', () => {
      if (!window.embeddedservice_bootstrap) {
        return;
      }

      // eslint-disable-next-line no-console
      console.log('Received the onEmbeddedMessagingReady event…');
      window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields(
        prechat,
      );
    });

    window.embeddedservice_bootstrap.init(...init);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error loading Embedded Messaging: ', err);
  }

  postInit();
};

export const useSalesforceMessaging = (
  src: string,
  init: Init,
  settings: Settings,
  prechat: PreChatFields,
  postInit: () => void,
) =>
  useEffect(() => {
    if (window.embeddedservice_bootstrap) {
      initEmbeddedMessaging(init, settings, prechat, postInit);
    } else {
      const script = document.createElement('script');
      script.onload = () =>
        initEmbeddedMessaging(init, settings, prechat, postInit);
      script.setAttribute('src', src);
      postInit();
      document.body.appendChild(script);
    }
  }, []);
