import cn from 'classnames';
import Discount32 from '@sats-group/icons/32/discount';
import LinkButton from '@sats-group/fresh-fitness-ui-lib/react/link-button';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';
import React from 'react';
import StarSticker32 from '@sats-group/icons/32/star-sticker';

import LayeredIcon from '../layered-icon/layered-icon';

import type { Discount as Props } from './discount.types';

const Discount: React.FC<Props> = ({
  action,
  active,
  onClick,
  texts,
  title,
}) => (
  <div className={cn('discount', { 'discount--active': active })}>
    <div className="discount__icon">
      <LayeredIcon
        icons={[
          { icon: <StarSticker32 />, color: 'dark' },
          { icon: <Discount32 />, color: 'light' },
        ]}
      />
    </div>
    <div className="discount__texts">
      <Text theme={Text.themes.emphasis}>{title}</Text>
      {texts.map(entry => (
        <Text key={entry} size={Text.sizes.small}>
          {entry}
        </Text>
      ))}
    </div>
    {action ? (
      <div className="discount__action">
        <LinkButton
          {...action.link}
          onClick={onClick}
          size={LinkButton.sizes.small}
          theme={LinkButton.themes.secondary}
        />
      </div>
    ) : null}
  </div>
);

export default Discount;
