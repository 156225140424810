import React from 'react';
import Message from '@sats-group/fresh-fitness-ui-lib/react/message';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';

import ContactOptions from '../contact-options/contact-options';

import type { ContactUs as Props } from './contact-us.types';

const ContactUs: React.FunctionComponent<Props> = ({
  messages,
  options,
  title,
}) => (
  <div className="contact-us">
    <div className="contact-us__title">
      <Text size={Text.sizes.header2} theme={Text.themes.emphasis}>
        {title}
      </Text>
    </div>
    {messages.map(message => (
      <Message {...message} key={message.text} />
    ))}
    <div className="contact-us__options">
      <ContactOptions {...options} />
    </div>
  </div>
);

export default ContactUs;
