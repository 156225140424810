import cn from 'classnames';
import Collapse from 'react-tiny-collapse';
import LinkButton from '@sats-group/fresh-fitness-ui-lib/react/link-button';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';
import Person18 from '@sats-group/icons/18/person';
import React, { useState } from 'react';

import ContentContainer from '../content-container/content-container';
import Logo from '../logo/logo';
import useToggle from '../../hooks/use-toggle';

import MenuDropdown from './menu-dropdown';
import type { Header as Props } from './header.types';

const MenuItem: React.FunctionComponent<Props['menuItems'][number]> = ({
  isActive,
  kind,
  props,
}) => {
  const isOpen = (open: boolean) => {
    if (isActive) {
      isActive(open);
    }
  };

  const { isSelected, ...rest } = props;

  return (
    <div className="header__menu-item">
      {kind === 'dropdown-item' ? (
        <MenuDropdown {...props} onToggle={isOpen} />
      ) : (
        <a
          className={cn('header__menu-item-link', {
            'header__menu-item-link--active': isSelected,
          })}
          {...rest}
        >
          <Text elementName="span" theme={Text.themes.medium}>
            {props.text}
          </Text>
        </a>
      )}
    </div>
  );
};

const drawerId = 'unique-drawer-id';

// eslint-disable-next-line react/no-multi-comp
const Header: React.FunctionComponent<Props> = ({
  closeLabel,
  dropdownShortcut,
  logo,
  navigationLandmarkLabel,
  openLabel,
  menuItems,
  shortcut,
  shortcuts,
}) => {
  const [isOpen, toggle] = useToggle(false);
  const [activeDropdownItem, setActiveDropdownItem] = useState(false);

  const hasItems = Boolean(menuItems.length);

  return (
    <header
      className={cn('header', {
        'header--active':
          menuItems.some(({ props }) => props.isSelected) || activeDropdownItem,
      })}
    >
      <ContentContainer>
        <div className="header__content">
          <div className="header__flanks">
            <div className="header__logo">
              <Logo {...logo} theme={Logo.themes.Red} />
            </div>
            <div className="header__menu-trigger-area">
              <LinkButton theme={LinkButton.themes.cta} {...shortcut} />
              {hasItems ? (
                <button
                  aria-controls={drawerId}
                  aria-expanded={isOpen}
                  aria-label={isOpen ? closeLabel : openLabel}
                  className={cn('header__menu-trigger', {
                    'header__menu-trigger--active': isOpen,
                  })}
                  onClick={toggle}
                >
                  <svg width={32} height={32} viewBox="0 0 32 32">
                    <path d="M2,8 L30,8 M2,24 L30,24" />
                  </svg>
                </button>
              ) : null}
            </div>
          </div>
          <div className="header__menu">
            <Collapse
              className="header__menu-collapse"
              componentProps={{ id: drawerId }}
              isOpen={isOpen}
              animateChildren={false}
            >
              <div className="header__menu-drawer">
                <div className="header__menu-items-section">
                  <nav
                    aria-label={navigationLandmarkLabel}
                    className="header__menu-items"
                  >
                    {menuItems.map(item => (
                      <MenuItem
                        {...item}
                        isActive={setActiveDropdownItem}
                        key={
                          item.kind === 'dropdown-item'
                            ? item.props.title
                            : item.props.href
                        }
                      />
                    ))}
                  </nav>
                </div>
                <div className="header__menu-dropdown-shortcut">
                  <a
                    className="header__menu-dropdown-shortcut-link"
                    href={dropdownShortcut.href}
                  >
                    <Person18 />
                    <Text theme={Text.themes.medium}>
                      {dropdownShortcut.text}
                    </Text>
                  </a>
                </div>
              </div>
            </Collapse>
          </div>
          <div className="header__shortcuts">
            {shortcuts.map((link, index) => (
              <LinkButton
                {...link}
                key={link.href}
                size={LinkButton.sizes.basic}
                theme={
                  index ? LinkButton.themes.cta : LinkButton.themes.ctaSecondary
                }
              />
            ))}
          </div>
        </div>
      </ContentContainer>
    </header>
  );
};

export default Header;
