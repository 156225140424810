import Forward18 from '@sats-group/icons/18/forward';
import Link from '@sats-group/fresh-fitness-ui-lib/react/link';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';
import React from 'react';

import type { ClubLink as Props } from './club-link.types';

const ClubLink: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  address,
  href,
  name,
}) => (
  <Link href={href} className="club-link">
    <div className="club-link__content">
      <div className="club-link__name">
        <Text elementName="span" size={Text.sizes.basic}>
          {name}
        </Text>
      </div>
      <Forward18 />
    </div>
    <div className="club-link__address">
      <Text size={Text.sizes.small}>{address}</Text>
    </div>
  </Link>
);

export default ClubLink;
