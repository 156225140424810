import cn from 'classnames';
import Forward18 from '@sats-group/icons/18/forward';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';
import React from 'react';

import Image from '../image/image';
import Tags from '../tags/tags';
import VisuallyButton from '../visually-button/visually-button';

import type { ArticleCard as Props } from './article-card.types';
import { variants } from './article-card.types';

const ArticleCard: React.FunctionComponent<Props> & {
  variants: typeof variants;
} = ({
  href,
  image,
  overlay,
  tags,
  text,
  title,
  type,
  variant = variants.primary,
}) => (
  <a
    className={cn('article-card', {
      [`article-card--variant-${variant}`]: variant,
    })}
    href={href}
    title={title}
  >
    <div
      className={cn('article-card__content', {
        [`article-card__content--type-${type}`]: type,
      })}
    >
      <div className="article-card__image">
        <Image {...image} aspectRatio={Image.aspectRatios.Fill} />
      </div>
      <div className="article-card__info-wrapper">
        <div className="article-card__info">
          {tags ? <Tags {...tags} /> : null}
          <Text size={Text.sizes.header3} theme={Text.themes.emphasis}>
            {title}
          </Text>
          {text ? (
            <div className="article-card__text">
              <Text>{text}</Text>
            </div>
          ) : null}
        </div>
      </div>
    </div>
    <div className="article-card__overlay">
      <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
        {overlay}
      </Text>
      <div>
        <VisuallyButton
          elementName="div"
          text={overlay}
          icon={<Forward18 />}
          iconOnly
          theme={VisuallyButton.themes.secondaryWhite}
        />
      </div>
    </div>
  </a>
);

ArticleCard.variants = variants;

export default ArticleCard;
