import cn from 'classnames';
import Forward18 from '@sats-group/icons/18/forward';
import Link from '@sats-group/fresh-fitness-ui-lib/react/link';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';
import React from 'react';

import ColorBlockLayout from 'root/client/components/color-block-layout/color-block-layout';
import Layout from 'root/client/components/layout/layout';
import TextModule from 'root/client/components/text-module/text-module';

import type { GroupExerciseTypesPage as Props } from './group-exercise-types-page.types';

const GroupExerciseTypesPage: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ content, groupExerciseList, layout, previousPage }) => (
  <Layout {...layout}>
    <div className="group-exercise-types-page">
      <ColorBlockLayout>
        <ColorBlockLayout.Block
          color={ColorBlockLayout.colors.Primary}
          padding={ColorBlockLayout.paddings.AllSides}
        >
          <div
            className={cn('group-exercise-types-page__main-content', {
              'group-exercise-types-page__main-content--is-parent':
                !previousPage,
            })}
          >
            <div className="group-exercise-types-page__previous-page">
              {previousPage ? <Link {...previousPage} /> : null}
            </div>
            <TextModule {...content} />
          </div>
        </ColorBlockLayout.Block>
        <ColorBlockLayout.Block
          color={ColorBlockLayout.colors.Secondary}
          padding={ColorBlockLayout.paddings.AllSides}
        >
          <div className="group-exercise-types-page__related-content">
            {groupExerciseList ? (
              <ul className="group-exercise-types-page__list">
                {groupExerciseList.map(exercise => (
                  <li
                    className="group-exercise-types-page__item"
                    key={exercise.id}
                  >
                    <Link
                      className="group-exercise-types-page__link"
                      href={exercise.link}
                    >
                      <div className="group-exercise-types-page_workout-type">
                        <Text size={Text.sizes.small}>
                          {exercise.workoutType}
                        </Text>
                      </div>
                      <div className="group-exercise-types-page__workout-name">
                        <Text size={Text.sizes.header3}>{exercise.name}</Text>
                        <Forward18 />
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        </ColorBlockLayout.Block>
      </ColorBlockLayout>
    </div>
  </Layout>
);

export default GroupExerciseTypesPage;
