import React from 'react';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';

import BreakOutContainer from '../../components/break-out-container/break-out-container';
import ContentContainer from '../../components/content-container/content-container';
import Image from '../../components/image/image';
import Layout from '../../components/layout/layout';
import RichText, { Theme } from '../../components/rich-text/rich-text';

import type { ArticlePage as Props } from './article-page.types';

const ArticlePage: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  body,
  image,
  introduction,
  layout,
  title,
}) => (
  <Layout {...layout}>
    <div className="article-page">
      <ContentContainer>
        {image ? (
          <BreakOutContainer>
            <div className="article-page__image">
              <Image {...image} aspectRatio={Image.aspectRatios.Fill} />
            </div>
          </BreakOutContainer>
        ) : null}

        <div className="article-page__text">
          <div className="article-page__title">
            <Text
              elementName="h1"
              size={Text.sizes.header2}
              variant={Text.variants.hero}
            >
              {title}
            </Text>
          </div>

          {introduction ? (
            <RichText
              className="article-page__introduction"
              theme={Theme.Intro}
              {...introduction}
            />
          ) : null}

          <RichText
            className="article-page__body"
            childClassNames={{ image: 'article-page__body-image' }}
            {...body}
          />
        </div>
      </ContentContainer>
    </div>
  </Layout>
);

export default ArticlePage;
