import type { ValueOf } from 'type-fest';

import type { Link } from '@sats-group/fresh-fitness-ui-lib/react/link/link.types';

export const themes = {
  information: 'information',
  success: 'success',
  warning: 'warning',
  error: 'error',
  neutral: 'neutral',
};

export type Banner = {
  action?: Link;
  text: string;
  title?: string;
  theme?: ValueOf<typeof themes>;
};
