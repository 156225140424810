import { isDefined } from 'list-fns';
import React from 'react';
import Link from '@sats-group/fresh-fitness-ui-lib/react/link';
import LinkButton from '@sats-group/fresh-fitness-ui-lib/react/link-button';
import Message from '@sats-group/fresh-fitness-ui-lib/react/message';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';

import ColorBlockLayout from 'root/client/components/color-block-layout/color-block-layout';
import EmbedYoutube from 'root/client/components/embed-youtube/embed-youtube';
import Image from 'root/client/components/image/image';
import Layout from 'root/client/components/layout/layout';
import OpeningHoursTable from 'root/client/components/opening-hours-table/opening-hours-table';
import PersonalTrainerCards from 'root/client/components/profile-card-list/profile-card-list';
import Slideshow from 'root/client/components/slideshow/slideshow';

import type { ClubPage as Props } from './club-page.types';

const ClubPage: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  address,
  addressTitle,
  dropInLink,
  scan,
  facilities,
  facilitiesTitle,
  equipment,
  equipmentTitle,
  information,
  layout,
  media,
  openingHours,
  personalTrainers,
  personalTrainersTitle,
  salesButton,
  slideshow,
  text,
  title,
}) => (
  <Layout {...layout}>
    <div className="club-page">
      <ColorBlockLayout>
        <ColorBlockLayout.Block color={ColorBlockLayout.colors.Primary}>
          <Text
            elementName="h1"
            size={Text.sizes.header2}
            variant={Text.variants.hero}
          >
            {title}
          </Text>
          {information ? <Message {...information} type="info" /> : null}
          {address ? (
            <div className="club-page__address">
              <Text elementName="h2" size={Text.sizes.header3}>
                {addressTitle}
              </Text>
              <Text>{address}</Text>
            </div>
          ) : null}
          {openingHours
            .map(openingHour => (
              <OpeningHoursTable {...openingHour} key={openingHour.title} />
            ))
            .filter(isDefined)}

          {dropInLink ? (
            <div className="club-page__drop-in">
              <Text elementName="h2" size={Text.sizes.header3}>
                {dropInLink.title}
              </Text>
              <Link data-test-drop-in-trigger {...dropInLink.link} />
            </div>
          ) : null}

          {salesButton ? (
            <div className="club-page__become-member">
              <LinkButton
                data-test-sales-flow
                size={LinkButton.sizes.small}
                theme={LinkButton.themes.cta}
                {...salesButton}
              />
            </div>
          ) : null}
        </ColorBlockLayout.Block>

        <ColorBlockLayout.Block color={ColorBlockLayout.colors.Secondary}>
          {media.length > 0 ? (
            <Slideshow {...slideshow}>
              {media.map(({ image, key, video }) =>
                video ? (
                  <EmbedYoutube key={key} {...video} />
                ) : image ? (
                  <Image key={key} {...image} />
                ) : null,
              )}
            </Slideshow>
          ) : null}
          {scan ? (
            <Link className="club-page__scan" {...scan}>
              <Text size={Text.sizes.large}>{scan.text}</Text>
            </Link>
          ) : null}
          {text ? (
            <div className="club-page__text">
              <Text>{text}</Text>
            </div>
          ) : null}
          {facilities.length > 0 ? (
            <div className="club-page__facilities">
              <Text elementName="h2" size={Text.sizes.header3}>
                {facilitiesTitle}
              </Text>

              <ul>
                {facilities.map(text => (
                  <li key={text}>{text}</li>
                ))}
              </ul>
            </div>
          ) : null}
          {equipment.length > 0 ? (
            <div className="club-page__equipment">
              <Text elementName="h2" size={Text.sizes.header3}>
                {equipmentTitle}
              </Text>

              <ul>
                {equipment.map(text => (
                  <li key={text}>{text}</li>
                ))}
              </ul>
            </div>
          ) : null}
          {personalTrainers ? (
            <div className="club-page__pts">
              <div className="club-page__pts-title">
                <Text elementName="h2" size={Text.sizes.header3}>
                  {personalTrainersTitle}
                </Text>
              </div>
              <PersonalTrainerCards {...personalTrainers} />
            </div>
          ) : null}
        </ColorBlockLayout.Block>
      </ColorBlockLayout>
    </div>
  </Layout>
);

export default ClubPage;
