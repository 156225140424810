import cn from 'classnames';
import Forward18 from '@sats-group/icons/18/forward';
import LinkButton from '@sats-group/fresh-fitness-ui-lib/react/link-button';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';
import React, { useEffect, useRef, useState } from 'react';

import useIsMounted from 'root/client/hooks/use-is-mounted';

import ProfileCard from '../profile-card/profile-card';
import ProfileLinkCard from '../profile-link-card/profile-link-card';

import type {
  ProfileCardList as Props,
  ProfileCardListItem as ItemProps,
} from './profile-card-list.types';

const ProfileCardListItem: React.FunctionComponent<
  React.PropsWithChildren<ItemProps>
> = ({ children, intersectionRoot, onIntersection }) => {
  const item = useRef(null);

  const handleIntersection: IntersectionObserverCallback = entries =>
    onIntersection
      ? onIntersection(
          entries.some(
            entry => entry.isIntersecting && entry.intersectionRatio === 1,
          ),
        )
      : undefined;

  useEffect(() => {
    if (!item.current) {
      return;
    }

    const observer = new IntersectionObserver(handleIntersection, {
      root: intersectionRoot ? intersectionRoot.current : undefined,
      threshold: 1,
    });

    observer.observe(item.current);

    return () => observer.disconnect();
  }, []);

  return (
    <div className="profile-card-list__item" ref={item}>
      {children}
    </div>
  );
};

// eslint-disable-next-line react/no-multi-comp
const ProfileCardList: React.FunctionComponent<Props> = ({
  actions,
  cards,
  slider,
  title,
}) => {
  const isMounted = useIsMounted();
  const cardSection = useRef(null);
  const [visibleCards, setVisibleCards] = useState<Record<string, boolean>>({});

  const handleIntersection = (id: string) => (isInView: boolean) =>
    setVisibleCards(value => {
      if (value[id] === isInView) {
        return value;
      }

      return Object.assign({}, value, { [id]: isInView });
    });

  return (
    <div className="profile-card-list">
      {title ? (
        <div className="profile-card-list__title">
          <Text
            size={Text.sizes.header3}
            theme={Text.themes.emphasis}
            variant={Text.variants.hero}
          >
            {title}
          </Text>
        </div>
      ) : null}
      <div className="profile-card-list__content">
        <div
          className={cn('profile-card-list__cards', {
            'profile-card-list__cards--slider': slider,
          })}
        >
          <div
            className={cn('profile-card-list__card-track', {
              'profile-card-list__card-track--active': slider,
            })}
            ref={cardSection}
          >
            {slider ? (
              <div className="profile-card-list__card profile-card-list__card--fake" />
            ) : null}
            {cards.map(card => (
              <div className="profile-card-list__card" key={card.name}>
                <ProfileCardListItem
                  onIntersection={handleIntersection(card.url)}
                >
                  <ProfileCard {...card} />
                </ProfileCardListItem>
              </div>
            ))}
            {actions.map(action => (
              <div
                className="profile-card-list__card profile-card-list__card--action"
                key={action.href}
              >
                <ProfileLinkCard {...action} />
              </div>
            ))}
            {slider ? (
              <div className="profile-card-list__card profile-card-list__card--fake" />
            ) : null}
          </div>
        </div>
        {isMounted && slider ? (
          <div className="profile-card-list__indicators">
            {cards.map(card => (
              <div
                className={cn('profile-card-list__indicator', {
                  'profile-card-list__indicator--active':
                    visibleCards[card.url],
                })}
                key={card.url}
              />
            ))}
          </div>
        ) : null}
        {slider && actions.length ? (
          <div className="profile-card-list__actions">
            {actions.map(action => (
              <div key={action.href}>
                <LinkButton
                  {...action}
                  icon={<Forward18 />}
                  iconPosition="right"
                  theme={LinkButton.themes.secondary}
                  wide
                />
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProfileCardList;
