import Chat24 from '@sats-group/icons/24/chat';
import Forward18 from '@sats-group/icons/18/forward';
import Headset24 from '@sats-group/icons/24/headset';
import LinkButton from '@sats-group/fresh-fitness-ui-lib/react/link-button';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';
import React from 'react';

import Band from '../band/band';
import ContentContainer from '../content-container/content-container';
import RichText from '../rich-text/rich-text';

import type { CustomerServiceBanner as Props } from './customer-service-banner.types';

const CustomerServiceBanner: React.FunctionComponent<Props> = ({
  actions,
  clubOpeningHoursArticle,
  customerServiceHours,
  introduction,
  phone,
  title,
}) => (
  <Band contentWidth={ContentContainer.widths.Medium}>
    <div className="customer-service-banner">
      <div className="customer-service-banner__title">
        <Chat24 />
        <Text size={Text.sizes.header2} tight>
          {title}
        </Text>
      </div>
      <div className="customer-service-banner__content">
        <div>
          <RichText {...introduction} />
          {actions.length ? (
            <div className="customer-service-banner__actions">
              {actions.map(action => (
                <LinkButton {...action} key={action.href} />
              ))}
            </div>
          ) : null}
        </div>
        <div>
          {phone ? (
            <LinkButton
              {...phone}
              icon={<Headset24 />}
              iconPosition="left"
              theme={LinkButton.themes.secondary}
            />
          ) : null}
          {customerServiceHours.length ? (
            <div className="customer-service-banner__cs">
              {customerServiceHours.map(text => (
                <Text elementName="div" key={text} size={Text.sizes.small}>
                  {text}
                </Text>
              ))}
            </div>
          ) : null}
          {clubOpeningHoursArticle ? (
            <LinkButton
              {...clubOpeningHoursArticle}
              icon={<Forward18 />}
              iconPosition="right"
              theme={LinkButton.themes.secondary}
            />
          ) : null}
        </div>
      </div>
    </div>
  </Band>
);

export default CustomerServiceBanner;
