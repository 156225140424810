import React from 'react';
// import Button from '@sats-group/fresh-fitness-ui-lib/react/button';

import { useSalesforceChat } from '../../hooks/use-salesforce-chat';
import { useSalesforceMessaging } from '../../hooks/use-salesforce-messaging';
import useToggle from '../../hooks/use-toggle';

import type { MemberChatTrigger as Props } from './member-chat-trigger.types';

const MemberChatTrigger: React.FunctionComponent<Props> = ({
  chat,
  deprecatedChat,
  // label,
}) => {
  const [, , enableTrigger] = useToggle(false);

  if (chat) {
    useSalesforceMessaging(
      chat.esw,
      chat.init,
      chat.settings,
      chat.preChat,
      enableTrigger,
    );
  } else if (deprecatedChat) {
    useSalesforceChat(
      deprecatedChat.esw,
      deprecatedChat.init,
      deprecatedChat.settings,
      enableTrigger,
    );
  }

  // const triggerChat = () => {
  //   if (chat) {
  //     if (!window.embeddedservice_bootstrap) {
  //       // NOTE: Show message?
  //       return;
  //     }

  //     if (
  //       typeof window.embeddedservice_bootstrap.utilAPI.launchChat() !==
  //       'function'
  //     ) {
  //       // NOTE: Show message?
  //       return;
  //     }

  //     window.embeddedservice_bootstrap.utilAPI.launchChat();
  //   } else if (deprecatedChat) {
  //     if (!window.embedded_svc) {
  //       // NOTE: Show message?
  //       return;
  //     }

  //     if (typeof window.embedded_svc.onHelpButtonClick !== 'function') {
  //       // NOTE: Show message?
  //       return;
  //     }

  //     window.embedded_svc.onHelpButtonClick();
  //   }
  // };

  return (
    <div className="member-chat-trigger">
      {/* <Button
        disabled={!isTriggerEnabled}
        onClick={() => triggerChat()}
        text={label}
      /> */}
    </div>
  );
};

export default MemberChatTrigger;
