import ArrowDown24 from '@sats-group/icons/24/arrow-down';
import cn from 'classnames';
import React from 'react';
import LinkButton from '@sats-group/fresh-fitness-ui-lib/react/link-button';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';

import Collapse from '../collapse/collapse';
import RichText from '../rich-text/rich-text';
import useToggle from '../../hooks/use-toggle';

import type { ContactOption as Props } from './contact-option.types';

const ContactOption: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ actions, children, content, isOpen, key, title }) => {
  const [_isOpen, toggleOpen] = useToggle(isOpen);

  return (
    <div className="contact-option" data-key={key}>
      <button className="contact-option__trigger" onClick={toggleOpen}>
        <div className="contact-option__title">
          <Text size={Text.sizes.basic} theme={Text.themes.emphasis}>
            {title}
          </Text>
        </div>
        <div
          className={cn('contact-option__flourish', {
            'contact-option__flourish--flipped': _isOpen,
          })}
        >
          <ArrowDown24 />
        </div>
      </button>
      <div className="contact-option__drawer">
        <Collapse isOpen={_isOpen}>
          <div className="contact-option__drawer-content">
            <div className="contact-option__content">
              <RichText {...content} />
            </div>
            {children ? (
              <div className="contact-option__children">{children}</div>
            ) : actions.length ? (
              <div className="contact-option__actions">
                {actions.map(action => (
                  <div key={action.href}>
                    <LinkButton {...action} />
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default ContactOption;
