import React from 'react';
import Expander from '@sats-group/fresh-fitness-ui-lib/react/expander';
import Message from '@sats-group/fresh-fitness-ui-lib/react/message';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';

import ContactUs from 'root/client/components/contact-us/contact-us';
import ContentContainer from 'root/client/components/content-container/content-container';
import Layout from 'root/client/components/layout/layout';
import PageHeader from 'root/client/components/page-header/page-header';
import RichText from 'root/client/components/rich-text/rich-text';
import SplitInfoModule from 'root/client/components/split-info-module/split-info-module';
import TextLinkCard from 'root/client/components/text-link-card/text-link-card';

import type { CustomerService as Props } from './customer-service.types';

const CustomerService: React.FunctionComponent<Props> = ({
  categories,
  categoriesDescription,
  categoriesTitle,
  contact,
  faq,
  header,
  highlight,
  layout,
  message,
}) => (
  <Layout {...layout} theme={Layout.themes.Primary}>
    <div className="customer-service">
      <PageHeader {...header} contentWidth={ContentContainer.widths.Medium} />
      <div className="customer-service__body">
        {message ? (
          <div>
            <ContentContainer width={ContentContainer.widths.Medium}>
              <Message {...message} type={Message.types.info} />
            </ContentContainer>
          </div>
        ) : null}
        <div>
          <ContentContainer width={ContentContainer.widths.Medium}>
            <div className="customer-service__categories-title">
              <Text size={Text.sizes.header2}>{categoriesTitle}</Text>
            </div>
            <div className="customer-service__categories-description">
              <Text size={Text.sizes.basic} theme={Text.themes.medium}>
                {categoriesDescription}
              </Text>
            </div>
            <ul className="customer-service__categories">
              {categories.map(entry => (
                <li key={entry.href}>
                  <TextLinkCard {...entry} />
                </li>
              ))}
            </ul>
          </ContentContainer>
        </div>
        <div>
          <ContentContainer width={ContentContainer.widths.Medium}>
            <Expander {...faq} itemRenderer={RichText} />
          </ContentContainer>
        </div>
        {highlight ? (
          <div>
            <ContentContainer width={ContentContainer.widths.Medium}>
              <SplitInfoModule {...highlight} />
            </ContentContainer>
          </div>
        ) : null}
        {contact ? (
          <div>
            <ContentContainer width={ContentContainer.widths.Medium}>
              <ContactUs {...contact} />
            </ContentContainer>
          </div>
        ) : null}
      </div>
    </div>
  </Layout>
);

export default CustomerService;
