import CheckBox36 from '@sats-group/icons/36/checkbox';
import Message from '@sats-group/fresh-fitness-ui-lib/react/message';
import Summary from '@sats-group/fresh-fitness-ui-lib/react/summary';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';
import React from 'react';

import CleanHeader from '../../components/clean-header/clean-header';

import type { DropInConfirmation as Props } from './drop-in-confirmation.types';

const DropInConfirmation: React.FC<Props> = ({
  header,
  information,
  messages,
  summary,
  title,
}) => (
  <div className="drop-in-confirmation">
    <CleanHeader {...header} />
    <div className="drop-in-confirmation__title-wrapper">
      <div className="drop-in-confirmation__icon">
        <CheckBox36 />
      </div>
      <Text
        className="drop-in-confirmation__title"
        theme={Text.themes.emphasis}
        size={Text.sizes.header2}
        variant={Text.variants.hero}
      >
        {title}
      </Text>
    </div>
    <div className="drop-in-confirmation__messages">
      {messages.map(entry => (
        <div key={entry.text}>
          <Message {...entry} />
        </div>
      ))}
    </div>
    <div className="drop-in-confirmation__content">
      {information ? (
        <div className="drop-in-confirmation__information">
          <Text>{information}</Text>
        </div>
      ) : null}
      {summary ? (
        <Summary
          {...summary}
          type={Summary.types.standalone}
          testId="confirmation-summary"
        />
      ) : null}
    </div>
  </div>
);

export default DropInConfirmation;
