import React, { useState } from 'react';
import Button from '@sats-group/fresh-fitness-ui-lib/react/button';
import Link from '@sats-group/fresh-fitness-ui-lib/react/link';
import Message from '@sats-group/fresh-fitness-ui-lib/react/message';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';

import Attachment from 'root/client/components/attachment/attachment';
import ColorBlockLayout from 'root/client/components/color-block-layout/color-block-layout';
import ContentContainer from 'root/client/components/content-container/content-container';
import HiddenInput from 'root/client/components/hidden-input/hidden-input';
import Layout from 'root/client/components/layout/layout';
import RadioButton from 'root/client/components/radio-button/radio-button';
import TextArea from 'root/client/components/text-area/text-area';
import TextField from 'root/client/components/text-field/text-field';
import useIsMounted from 'root/client/hooks/use-is-mounted';

import type { MemberCareFormPage as Props } from './member-care-form-page.types';

const MemberCareFormPage: React.FunctionComponent<Props> = ({
  attachment,
  categories,
  categoryHelperText,
  categoryLegend,
  countryIsoCode,
  description,
  endpoint,
  errorMessage,
  errorRedirectUrl,
  help,
  layout,
  nudge,
  previousPage,
  submit,
  successMessage,
  successRedirectUrl,
  textInputs = [],
  title,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isMounted = useIsMounted();

  const handleSubmit: React.FormEventHandler = event => {
    if (!(event.target as HTMLFormElement).checkValidity()) {
      event.preventDefault();
      return;
    }

    setIsSubmitting(true);
  };

  return (
    <Layout
      {...layout}
      theme={successMessage ? Layout.themes.Primary : undefined}
    >
      {successMessage ? (
        <ContentContainer width={ContentContainer.widths.Narrow}>
          <div className="member-care-form-page__success">
            <Message
              key={successMessage.title}
              type={Message.types.success}
              {...successMessage}
            />
          </div>
        </ContentContainer>
      ) : (
        <ColorBlockLayout>
          <ColorBlockLayout.Block
            color={ColorBlockLayout.colors.Primary}
            padding={ColorBlockLayout.paddings.AllSides}
          >
            {previousPage ? (
              <div className="member-care-form-page__previous-page">
                <Link {...previousPage} />
              </div>
            ) : null}
            <Text
              elementName="h1"
              size={Text.sizes.header2}
              variant={Text.variants.hero}
            >
              {title}
            </Text>
            <div className="member-care-form-page__nudge">
              <Message {...nudge} type={Message.types.success} />
            </div>
          </ColorBlockLayout.Block>
          <ColorBlockLayout.Block
            color={ColorBlockLayout.colors.Secondary}
            padding={ColorBlockLayout.paddings.AllSides}
          >
            <form
              className="member-care-form-page"
              action={endpoint}
              method="POST"
              encType="multipart/form-data"
              onSubmit={handleSubmit}
            >
              <HiddenInput {...countryIsoCode} />
              {isMounted ? (
                <>
                  <HiddenInput
                    name="redirect-success"
                    value={`${window.location.origin}${successRedirectUrl}`}
                  />
                  <HiddenInput
                    name="redirect-error"
                    value={`${window.location.origin}${errorRedirectUrl}`}
                  />
                </>
              ) : null}
              {errorMessage ? (
                <div className="member-care-form-page__error-message">
                  <Message key={errorMessage.text} {...errorMessage} />
                </div>
              ) : null}
              <div className="member-care-form-page__help">
                <Text>{help}</Text>
              </div>
              <div className="member-care-form-page__inputs">
                {textInputs.map(item => (
                  <div key={item.name} className="member-care-form-page__input">
                    <TextField {...item} />
                  </div>
                ))}
              </div>
              {categories ? (
                <fieldset className="member-care-form-page__radio-group">
                  <div className="member-care-form-page__legend">
                    <Text elementName="legend" size={Text.sizes.small}>
                      {categoryLegend}
                    </Text>
                  </div>
                  {categories.map(item => (
                    <RadioButton key={item.value} {...item} required />
                  ))}
                  {categoryHelperText ? (
                    <div className="member-care-form-page__helper-text">
                      {categoryHelperText}
                    </div>
                  ) : null}
                </fieldset>
              ) : null}
              <TextArea {...description} rows={5} />
              {attachment ? (
                <div className="member-care-form-page__attachment">
                  <Attachment {...attachment} />
                </div>
              ) : null}
              <div className="member-care-form-page__submit">
                <Button
                  data-test-submit
                  type="submit"
                  disabled={isSubmitting}
                  text={submit}
                />
              </div>
            </form>
          </ColorBlockLayout.Block>
        </ColorBlockLayout>
      )}
    </Layout>
  );
};

export default MemberCareFormPage;
