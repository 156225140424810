import cn from 'classnames';
import Forward18 from '@sats-group/icons/18/forward';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';
import React from 'react';

import Image from 'root/client/components/image/image';
import VisuallyButton from 'root/client/components/visually-button/visually-button';

import type { LinkCard as Props } from './link-card.types';

const LinkCard: React.FunctionComponent<Props> = ({
  ariaLabel,
  collapsed,
  href,
  image,
  title,
}) => (
  <a href={href} className="link-card">
    <div
      className={cn('link-card__image', {
        'link-card__image--collapsed': collapsed,
      })}
    >
      <Image {...image} aspectRatio={Image.aspectRatios.SixteenNine} />
    </div>
    <div className="link-card__text">
      <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
        {title}
      </Text>
      <div>
        <VisuallyButton
          aria-label={ariaLabel}
          elementName="div"
          icon={<Forward18 />}
          iconOnly
          text=""
          theme={VisuallyButton.themes.secondary}
        />
      </div>
    </div>
  </a>
);

export default LinkCard;
