import React from 'react';

import MemberChatTrigger from '../member-chat-trigger/member-chat-trigger';

import type { MemberChat as Props } from './member-chat.types';

const MemberChat: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  config,
}) => (
  <div className="member-chat">
    {config ? <MemberChatTrigger {...config} /> : null}
  </div>
);

export default MemberChat;
