import Forward18 from '@sats-group/icons/18/forward';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';
import React from 'react';

import VisuallyButton from 'root/client/components/visually-button/visually-button';

import type { TextLinkCard as Props } from './text-link-card.types';

const TextLinkCard: React.FunctionComponent<Props> = ({ href, text }) => (
  <a href={href} className="text-link-card">
    <div className="text-link-card__text">
      <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
        {text}
      </Text>
      <div>
        <VisuallyButton
          elementName="div"
          icon={<Forward18 />}
          iconOnly
          text=""
          theme={VisuallyButton.themes.secondary}
        />
      </div>
    </div>
  </a>
);

export default TextLinkCard;
