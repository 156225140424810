import cn from 'classnames';
import React, { useMemo, useRef, useState } from 'react';
import Button from '@sats-group/fresh-fitness-ui-lib/react/button';
import LinkButton from '@sats-group/fresh-fitness-ui-lib/react/link-button';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';
import SvgPause from '@sats-group/icons/18/pause';
import SvgPlay from '@sats-group/icons/18/play';

import ContentContainer from '../content-container/content-container';
import Image from '../image/image';

import type { Hero as Props } from './hero.types';
import { MediaType } from 'root/shared/media-type-helper';
import type { BackgroundVideoRef } from '../background-video/background-video';
import BackgroundVideo from '../background-video/background-video';

const Hero: React.FunctionComponent<Props> = ({
  actions,
  blurb,
  disclaimer,
  media,
  short,
  texts,
  title,
}) => {
  const videoRef = useRef<BackgroundVideoRef>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>();

  const handlePause = () => setIsPlaying(false);
  const handleTimeUpdate = () => setIsPlaying(true);

  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const playVideo = () => {
    if (videoRef.current) {
      try {
        videoRef.current.play();
      } catch {
        // Noop
      }
    }
  };

  const hasVideo = media && media.type === MediaType.Video;

  const videoTrigger = useMemo(() => {
    if (!hasVideo) {
      return null;
    }

    if (isPlaying) {
      return (
        <Button
          text={''}
          icon={<SvgPause />}
          iconOnly
          onClick={pauseVideo}
          theme={Button.themes.secondaryWhite}
        />
      );
    }

    return (
      <Button
        text={''}
        icon={<SvgPlay />}
        iconOnly
        onClick={playVideo}
        theme={Button.themes.secondaryWhite}
      />
    );
  }, [hasVideo, isPlaying]);

  return (
    <div className={cn('hero', { 'hero--short': short })}>
      <React.Fragment>
        {media.type === MediaType.Image ? (
          <React.Fragment>
            <div className="hero__image hero__image--static">
              <Image {...media.props} />
            </div>
            <div className="hero__image hero__image--fill">
              <Image {...media.props} aspectRatio={Image.aspectRatios.Fill} />
            </div>
          </React.Fragment>
        ) : media.type === MediaType.Video ? (
          <div className="hero__video">
            <BackgroundVideo
              {...media.props}
              onPause={handlePause}
              onTimeUpdate={handleTimeUpdate}
              ref={videoRef}
            />
            <div className="hero__video-trigger">{videoTrigger}</div>
          </div>
        ) : null}
      </React.Fragment>
      <div className="hero__content">
        <ContentContainer>
          {blurb ? (
            <div className="hero__blurb">
              <Text
                size={Text.sizes.header1}
                tight
                variant={Text.variants.contrast}
              >
                {blurb}
              </Text>
            </div>
          ) : null}
          <div className="hero__title">
            <Text
              elementName="h1"
              size={Text.sizes.header1}
              tight
              variant={Text.variants.hero}
            >
              {title}
            </Text>
          </div>
          {texts.length ? (
            <div className="hero__texts">
              {texts.map(line => (
                <Text key={line}>{line}</Text>
              ))}
            </div>
          ) : null}
          {actions.length || videoTrigger ? (
            <React.Fragment>
              <div className="hero__actions hero__actions--vertical">
                {actions.map((action, index) => (
                  <LinkButton
                    {...action}
                    key={action.href}
                    theme={
                      index
                        ? LinkButton.themes.ctaSecondary
                        : LinkButton.themes.cta
                    }
                    wide
                  />
                ))}
                {videoTrigger ? (
                  <div className="hero__action hero__action--trigger">
                    {videoTrigger}
                  </div>
                ) : null}
              </div>
              <div className="hero__actions hero__actions--horizontal">
                {actions.map((action, index) => (
                  <LinkButton
                    {...action}
                    key={action.href}
                    theme={
                      index
                        ? LinkButton.themes.ctaSecondaryWhite
                        : LinkButton.themes.cta
                    }
                  />
                ))}
                {videoTrigger ? (
                  <div className="hero__action hero__action--trigger">
                    {videoTrigger}
                  </div>
                ) : null}
              </div>
            </React.Fragment>
          ) : null}
          {disclaimer ? (
            <div className="hero__disclaimer">
              <Text size={Text.sizes.interface}>{disclaimer}</Text>
            </div>
          ) : null}
        </ContentContainer>
      </div>
    </div>
  );
};

export default Hero;
