import Forward18 from '@sats-group/icons/18/forward';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';
import React from 'react';

import useCounter from 'root/client/hooks/use-counter';

import type { Slideshow as Props } from './slideshow.types';

const Slideshow: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  initialSlide = 0,
  nextButtonText,
  previousButtonText,
}) => {
  const slidesCount = React.Children.count(children);
  const maxIndex = slidesCount - 1;
  const [current, next, previous] = useCounter(0, maxIndex, initialSlide);

  const trackStyle = { transform: `translateX(${-current * 100}%)` };

  return (
    <div className="slideshow">
      <div className="slideshow__track-container">
        <div className="slideshow__track" style={trackStyle}>
          {React.Children.map(children, child => (
            <div className="slideshow__slide">{child}</div>
          ))}
        </div>
      </div>

      <div className="slideshow__nav">
        <button
          aria-label={previousButtonText}
          className="slideshow__previous"
          onClick={previous}
          disabled={current === 0}
        >
          <Forward18 />
        </button>

        <div className="slideshow__count">
          <Text elementName="div" tight>{`${current + 1}/${slidesCount}`}</Text>
        </div>

        <button
          aria-label={nextButtonText}
          className="slideshow__next"
          onClick={next}
          disabled={current === maxIndex}
        >
          <Forward18 />
        </button>
      </div>
    </div>
  );
};

export default Slideshow;
