import React from 'react';

import ContactOption from '../contact-option/contact-option';
import MemberChat from '../member-chat/member-chat';

import type { ContactOptions as Props } from './contact-options.types';

const ContactOptions: React.FunctionComponent<Props> = ({ chat, entries }) => (
  <div className="contact-options">
    {entries.map(entry => (
      <ContactOption
        {...entry}
        children={
          entry.key === 'chat' && chat ? <MemberChat {...chat} /> : undefined
        }
        key={entry.key}
      />
    ))}
  </div>
);

export default ContactOptions;
