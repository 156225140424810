import cn from 'classnames';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';
import React from 'react';

import type { Tag as Props } from './tag.types';

const Tag: React.FunctionComponent<Props> = ({ text, theme }) => (
  <div className={cn('tag', { [`tag--${theme}`]: theme })}>
    <Text elementName="div" size={Text.sizes.interface}>
      {text}
    </Text>
  </div>
);

export default Tag;
