/* eslint-disable react/no-multi-comp */
import CheckBox36 from '@sats-group/icons/36/checkbox';
import Expander from '@sats-group/fresh-fitness-ui-lib/react/expander';
import Message from '@sats-group/fresh-fitness-ui-lib/react/message';
import Summary from '@sats-group/fresh-fitness-ui-lib/react/summary';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';
import React from 'react';

import ArticleCard from '../../components/article-card/article-card';
import CleanHeader from '../../components/clean-header/clean-header';

import type {
  CheckoutConfirmation as Props,
  Summary as SummaryProps,
} from './checkout-confirmation.types';

const SummaryRenderer: React.FC<SummaryProps> = summary => (
  <Summary
    {...summary}
    type={Summary.types.standalone}
    testId="confirmation-summary"
  />
);

const CheckoutConfirmation: React.FC<Props> = ({
  header,
  information,
  messages,
  more,
  summary,
  title,
}) => (
  <div className="checkout-confirmation">
    <CleanHeader {...header} />
    <div className="checkout-confirmation__title-wrapper">
      <div className="checkout-confirmation__icon">
        <CheckBox36 />
      </div>
      <Text
        className="checkout-confirmation__title"
        theme={Text.themes.emphasis}
        size={Text.sizes.header2}
        variant={Text.variants.hero}
      >
        {title}
      </Text>
    </div>
    <div className="checkout-confirmation__messages">
      {messages.map(entry => (
        <div key={entry.text}>
          <Message {...entry} />
        </div>
      ))}
    </div>
    <div className="checkout-confirmation__content">
      {information ? (
        <div>
          <Text>{information}</Text>
        </div>
      ) : null}

      {summary ? (
        <div>
          <Expander {...summary} itemRenderer={SummaryRenderer} />
        </div>
      ) : null}
      <div className="checkout-confirmation__more">
        {more.map(article => (
          <div>
            <ArticleCard {...article} />
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default CheckoutConfirmation;
