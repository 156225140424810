import Spinner from '@sats-group/fresh-fitness-ui-lib/react/spinner';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';
import React from 'react';

import CleanHeader from 'root/client/components/clean-header/clean-header';

import type { DropInStatus as Props } from './drop-in-status.types';

const DropInStatus: React.FunctionComponent<Props> = ({
  header,
  information,
}) => (
  <div className="drop-in-status">
    <CleanHeader {...header} />
    <div className="drop-in-status__content">
      <div className="drop-in-status__processing-wrapper" data-testId="status">
        <div aria-hidden className="drop-in-payment__loader">
          <Spinner />
        </div>
        <Text size={Text.sizes.large}>{information}</Text>
      </div>
    </div>
  </div>
);

export default DropInStatus;
