import cn from 'classnames';
import Forward18 from '@sats-group/icons/18/forward';
import LinkButton from '@sats-group/fresh-fitness-ui-lib/react/link-button';
import NavigationCard from '@sats-group/fresh-fitness-ui-lib/react/navigation-card';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';
import React from 'react';

import Image from '../image/image';
import RichText from '../rich-text/rich-text';
import VisuallyButton from '../visually-button/visually-button';

import type { SplitInfoModule as Props } from './split-info-module.types';

const SplitInfoModule: React.FunctionComponent<Props> = ({
  actions,
  body,
  cards,
  featured,
  flipped,
  image,
  text,
  title,
}) => {
  const singleAction = cards?.length
    ? undefined
    : actions.length === 1
      ? actions[0]
      : undefined;

  return React.createElement(
    singleAction ? 'a' : 'div',
    {
      className: 'split-info-module',
      href: singleAction ? singleAction.href : undefined,
      title: singleAction ? singleAction.text : undefined,
    },
    <div
      className={cn('split-info-module__inner', {
        'split-info-module__inner--flipped': flipped,
        'split-info-module__inner--merged': !cards?.length,
        'split-info-module__inner--split': cards?.length,
      })}
    >
      <div
        className={cn('split-info-module__secondary', {
          'split-info-module__secondary--grid': cards?.length,
        })}
      >
        {cards?.map(card => (
          <div className="split-info-module__card" key={card.href}>
            <NavigationCard {...card} />
          </div>
        ))}
        {image ? (
          <div className="split-info-module__image">
            <Image {...image} aspectRatio={Image.aspectRatios.Fill} />
          </div>
        ) : null}
      </div>
      <div
        className={cn('split-info-module__primary', {
          'split-info-module__primary--block': cards?.length,
          'split-info-module__primary--featured': featured,
        })}
      >
        <div className="split-info-module__primary-content">
          <div className="split-info-module__text">
            <Text size={Text.sizes.header1} theme={Text.themes.emphasis}>
              {title}
            </Text>

            {text && text.length ? (
              <div className="split-info-module__texts">
                {text.map(t => (
                  <Text key={t}>{t}</Text>
                ))}
              </div>
            ) : null}
            <div className="split-info-module__texts">
              {body ? <RichText {...body} /> : null}
            </div>
          </div>

          <div className="split-info-module__actions">
            {singleAction ? (
              <VisuallyButton
                elementName="div"
                text={singleAction.text}
                theme={
                  featured
                    ? VisuallyButton.themes.primaryWhite
                    : VisuallyButton.themes.primary
                }
              />
            ) : (
              actions.map((action, index) =>
                action.text ? (
                  <div className="split-info-module__actions" key={action.href}>
                    <LinkButton
                      {...action}
                      icon={index ? <Forward18 /> : undefined}
                      iconPosition="right"
                      theme={
                        action.theme === LinkButton.themes.complete
                          ? LinkButton.themes.complete
                          : featured
                            ? index
                              ? LinkButton.themes.secondaryWhite
                              : LinkButton.themes.primaryWhite
                            : index
                              ? LinkButton.themes.secondary
                              : LinkButton.themes.primary
                      }
                    />
                  </div>
                ) : null,
              )
            )}
          </div>
        </div>
      </div>
    </div>,
  );
};

export default SplitInfoModule;
