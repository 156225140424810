import React from 'react';
import Link from '@sats-group/fresh-fitness-ui-lib/react/link';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';

import ContentContainer from 'root/client/components/content-container/content-container';
import Layout from 'root/client/components/layout/layout';

import type { ErrorPage as Props } from './error-page.types';

const ErrorPage: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  layout,
  title,
  text,
  links = [],
}) => (
  <Layout {...layout} theme={Layout.themes.Primary}>
    <ContentContainer className="error-page">
      <div className="error-page__title">
        <Text
          elementName="h1"
          size={Text.sizes.header1}
          variant={Text.variants.hero}
        >
          {title}
        </Text>
      </div>
      {text ? (
        <div className="error-page__text">
          <Text>{text}</Text>
        </div>
      ) : null}
      <ul className="error-page__links">
        {links.map(link => (
          <li key={link.href}>
            <Link {...link} />
          </li>
        ))}
      </ul>
    </ContentContainer>
  </Layout>
);

export default ErrorPage;
