import ArrowDown24 from '@sats-group/icons/24/arrow-down';
import cn from 'classnames';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';
import React from 'react';

import type { Select as Props } from './select.types';

const Select: React.FunctionComponent<Props> = ({
  badge,
  defaultValue,
  isLabelVisible = true,
  label,
  name,
  onChange,
  options,
}) => (
  <label
    className={cn('select', {
      'select--badged': badge,
    })}
  >
    {isLabelVisible && label ? (
      <div className="select__label">
        <Text size={Text.sizes.small} theme={Text.themes.emphasis}>
          {label}
        </Text>
      </div>
    ) : null}
    <div className="select__native-wrapper">
      <select
        aria-label={isLabelVisible ? undefined : label}
        defaultValue={defaultValue}
        name={name}
        onChange={onChange}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
    <div className="select__extra">
      {badge ? (
        <div className="select__badge">
          <Text>{badge}</Text>
        </div>
      ) : null}
      <div className="select__icon">
        <ArrowDown24 />
      </div>
    </div>
  </label>
);

export default Select;
