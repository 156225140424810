import React from 'react';
import Link from '@sats-group/fresh-fitness-ui-lib/react/link';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';

import ArticleList from '../../components/article-list/article-list';
import ContentContainer from '../../components/content-container/content-container';
import Layout from '../../components/layout/layout';

import type { ArticlesPage as Props } from './articles-page.types';

const ArticlesPage: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  articles,
  categories,
  intro,
  layout,
  title,
}) => (
  <Layout {...layout}>
    <div className="articles-page">
      <div className="articles-page__title">
        <ContentContainer>
          <Text
            elementName="h1"
            size={Text.sizes.header2}
            variant={Text.variants.hero}
          >
            {title}
          </Text>
        </ContentContainer>
      </div>
      <div>
        <ContentContainer width={ContentContainer.widths.Medium}>
          <Text>{intro}</Text>
        </ContentContainer>
      </div>
      {categories.length ? (
        <div>
          <ContentContainer>
            <div className="articles-page__categories">
              {categories.map(category => (
                <Link {...category} key={category.href} />
              ))}
            </div>
          </ContentContainer>
        </div>
      ) : null}
      <div>
        <ContentContainer>
          <ArticleList {...articles} />
        </ContentContainer>
      </div>
    </div>
  </Layout>
);

export default ArticlesPage;
