import Spinner from '@sats-group/fresh-fitness-ui-lib/react/spinner';
import Summary from '@sats-group/fresh-fitness-ui-lib/react/summary';
import LinkButton from '@sats-group/fresh-fitness-ui-lib/react/link-button';
import React, { useRef, useState } from 'react';

import { replaceQueryParameters } from 'root/shared/replace-query-parameters';

import Banner from '../../components/banner/banner';
import SalesLayout from '../../components/sales-layout/sales-layout';
import { type Core, useAdyen } from '../../hooks/use-adyen';
import useAsyncEffect from '../../hooks/use-async-effect';

import type { DropInPayment as Props } from './drop-in-payment.types';

const DropInPayment: React.FC<Props> = ({
  actions,
  layout,
  messages,
  payment,
  summaries,
}) => {
  const checkout = useRef<Core>();
  const dropinContainer = useRef<HTMLDivElement>(null);
  const [localMessages, setLocalMessages] = useState<
    Parameters<typeof Banner>[0][]
  >([]);
  const handleAdyenLoadingError = () =>
    setLocalMessages([messages.errorLoading]);
  const [isLoading, hasLoaded] = useAdyen(handleAdyenLoadingError);

  useAsyncEffect(async () => {
    if (
      !dropinContainer.current ||
      !hasLoaded ||
      !payment.session ||
      !window.AdyenCheckout ||
      isLoading
    ) {
      return;
    }

    setLocalMessages([]);

    const adyenConfiguration: Parameters<typeof window.AdyenCheckout>[0] = {
      clientKey: payment.clientKey,
      environment: payment.environment,
      locale: payment.locale,
      onError: () => {
        setLocalMessages([messages.errorCheckout]);
        if (checkout.current) {
          checkout.current.update();
        }
      },
      onPaymentCompleted: response => {
        setLocalMessages([messages.paymentComplete[response.resultCode]]);

        switch (response.resultCode) {
          case 'Authorised': {
            const staleHref = replaceQueryParameters(window.location.href, {
              [payment.staleKey]: payment.staleKey,
            });

            window.history.replaceState(null, document.title, staleHref);
            window.location.assign(payment.nextHref);

            break;
          }
          default: {
            if (checkout.current) {
              checkout.current.update();
            }
          }
        }
      },
      paymentMethodsConfiguration: {
        card: {
          challengeWindowSize: '05',
          hasHolderName: true,
          holderNameRequired: true,
          name: payment.cardName,
        },
      },
      session: payment.session,
    };

    const _checkout = await window.AdyenCheckout(adyenConfiguration);
    _checkout.create('dropin').mount(dropinContainer.current);
    checkout.current = _checkout;
  }, [isLoading, hasLoaded, payment]);

  return (
    <SalesLayout {...layout}>
      <div className="drop-in-payment">
        <noscript>
          <Banner {...messages.noscript} />
        </noscript>
        <div className="drop-in-payment__messages">
          {localMessages.map(entry => (
            <div key={entry.text}>
              <Banner {...entry} />
            </div>
          ))}
        </div>
        {summaries.map(summary => (
          <Summary {...summary} key={summary.title} />
        ))}
        {isLoading ? (
          <div aria-hidden className="drop-in-payment__loader">
            <Spinner />
          </div>
        ) : null}
        <div ref={dropinContainer} />
        {actions.length ? (
          <div className="drop-in-payment__actions">
            {actions.map(entry => (
              <div key={entry.href}>
                <LinkButton
                  {...entry}
                  theme={LinkButton.themes.secondary}
                  wide
                />
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </SalesLayout>
  );
};

export default DropInPayment;
