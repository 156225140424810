import ArrowDown from '@sats-group/icons/24/arrow-down';
import Button from '@sats-group/fresh-fitness-ui-lib/react/button';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';
import cn from 'classnames';
import React, { type FormEventHandler, useRef } from 'react';

import Collapse from '../collapse/collapse';
import Discount from '../discount/discount';
import HiddenInput from '../hidden-input/hidden-input';
import TextField from '../text-field/text-field';
import useToggle from '../../hooks/use-toggle';

import type { DiscountSection as Props } from './discount-section.types';
import { replaceQueryParameters } from 'root/shared/replace-query-parameters';

const DiscountSection: React.FunctionComponent<Props> = ({
  active,
  description,
  id,
  input,
  onInteraction,
  options,
  title,
  trigger,
}) => {
  const codeInput = useRef<HTMLInputElement>(null);
  const [isOpen, toggleOpen] = useToggle(false);

  const handleDiscountClick =
    (hrefs: {
      data: string;
      gui: string;
    }): NonNullable<Parameters<typeof Discount>[0]['onClick']> =>
    event => {
      if (!onInteraction) {
        return;
      }

      event.preventDefault();
      onInteraction(hrefs);
    };

  const handleSubmit: FormEventHandler<HTMLFormElement> = event => {
    if (!onInteraction) {
      return;
    }

    event.preventDefault();

    if (!codeInput.current) {
      return;
    }

    const options = Object.assign(
      Object.fromEntries(input.data.map(entry => [entry.name, entry.value])),
      { [input.code.name]: codeInput.current.value },
    );

    onInteraction({
      data: replaceQueryParameters(input.api, options),
      gui: replaceQueryParameters(input.action, options),
    });
  };

  return (
    <div className="discount-section">
      <div className="discount-section__text discount-section__text--contrast">
        <Text
          size={Text.sizes.large}
          theme={Text.themes.emphasis}
          variant={Text.variants.hero}
        >
          {title}
        </Text>
      </div>
      {active ? (
        <div className="discount-section__active">
          <div className="discount-section__text discount-section__text--muted">
            <Text>{active.title}</Text>
          </div>
          <Discount
            {...active.discount}
            active
            onClick={
              active.discount.action
                ? handleDiscountClick({
                    data: active.discount.action.api,
                    gui: active.discount.action.link.href,
                  })
                : undefined
            }
          />
        </div>
      ) : null}
      <div className="discount-section__change">
        <button
          aria-controls={id}
          aria-expanded={isOpen}
          className="discount-section__trigger"
          onClick={toggleOpen}
        >
          <Text>{trigger}</Text>
          <div
            className={cn('discount-section__trigger-flourish', {
              'discount-section__trigger-flourish--active': isOpen,
            })}
          >
            <ArrowDown />
          </div>
        </button>
        <Collapse isOpen={isOpen}>
          <div className="discount-section__content" id={id}>
            <div className="discount-section__text discount-section__text--muted">
              <Text size={Text.sizes.small}>{description}</Text>
            </div>
            <form
              action={input.action}
              className="discount-section__input"
              method="get"
              onSubmit={handleSubmit}
            >
              {input.data.map(entry => (
                <HiddenInput {...entry} key={`${entry.name}-${entry.value}`} />
              ))}
              <div>
                <TextField {...input.code} ref={codeInput} required wide />
              </div>
              <div className="discount-section__apply">
                <label aria-hidden>
                  <Text size={Text.sizes.basic}>{input.trigger.text}</Text>
                </label>
                <Button
                  {...input.trigger}
                  size={Button.sizes.small}
                  theme={Button.themes.secondary}
                  type="submit"
                  wide
                />
              </div>
            </form>
            {options ? (
              <div className="discount-section__options">
                <div className="discount-section__text discount-section__text--muted">
                  <Text size={Text.sizes.small} theme={Text.themes.emphasis}>
                    {options.title}
                  </Text>
                </div>
                {options.entries.map(entry => (
                  <div key={entry.title}>
                    <Discount
                      {...entry}
                      onClick={
                        entry.action
                          ? handleDiscountClick({
                              data: entry.action.api,
                              gui: entry.action.link.href,
                            })
                          : undefined
                      }
                    />
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default DiscountSection;
