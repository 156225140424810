import React from 'react';
import LinkButton from '@sats-group/fresh-fitness-ui-lib/react/link-button';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';

import Image from '../image/image';

import type { ProfileContactCard as Props } from './profile-contact-card.types';

const ProfileContactCard: React.FunctionComponent<Props> = ({
  actions,
  contact,
  image,
}) => (
  <div className="profile-contact-card">
    {image ? (
      <div className="profile-contact-card__image">
        <Image {...image} />
      </div>
    ) : null}
    <div className="profile-contact-card__text">
      {contact.items.length ? (
        <div className="profile-contact-card__contact">
          <Text size={Text.sizes.header3} theme={Text.themes.emphasis}>
            {contact.title}
          </Text>
          <div className="profile-contact-card__contact-items">
            {contact.items.map(item => (
              <div key={item.description}>
                <Text>{item.description}</Text>{' '}
                {item.href ? (
                  <Text>
                    <a href={item.href}>{item.value}</a>
                  </Text>
                ) : (
                  <Text>{item.value}</Text>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {actions.length ? (
        <div className="profile-contact-card__actions">
          {actions.map((action, index) => (
            <div className="profile-contact-card__action" key={action.href}>
              <LinkButton
                {...action}
                theme={
                  index
                    ? LinkButton.themes.complete
                    : LinkButton.themes.secondary
                }
                wide
              />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  </div>
);

export default ProfileContactCard;
