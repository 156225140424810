import React from 'react';
import Button from '@sats-group/fresh-fitness-ui-lib/react/button';
import Message from '@sats-group/fresh-fitness-ui-lib/react/message';
import Text from '@sats-group/fresh-fitness-ui-lib/react/text';
import Search18 from '@sats-group/icons/18/search';

import ContentContainer from 'root/client/components/content-container/content-container';
import Layout from 'root/client/components/layout/layout';
import PageHeader from 'root/client/components/page-header/page-header';
import PersonalTrainerCards from 'root/client/components/profile-card-list/profile-card-list';
import Select from 'root/client/components/select/select';
import TextField from 'root/client/components/text-field/text-field';

import type { PersonalTrainersPage as Props } from './personal-trainers-page.types';

const PersonalTrainersPage: React.FunctionComponent<Props> = ({
  filters,
  header,
  layout,
  personalTrainers,
  results,
  warning,
}) => {
  const handleClubChange: React.ChangeEventHandler<
    HTMLSelectElement
  > = event => {
    const href = filters.clubs.links[event.target.value];
    if (href) {
      window.location.assign(href);
    }
  };

  const handleExpertiseChange: React.ChangeEventHandler<
    HTMLSelectElement
  > = event => {
    const href = filters.expertise.links[event.target.value];
    if (href) {
      window.location.assign(href);
    }
  };

  const handleLevelChange: React.ChangeEventHandler<
    HTMLSelectElement
  > = event => {
    const href = filters.levels.links[event.target.value];
    if (href) {
      window.location.assign(href);
    }
  };

  return (
    <div className="personal-trainers-page">
      <Layout {...layout}>
        <PageHeader {...header} />
        <div className="personal-trainers-page__filters">
          <ContentContainer>
            <div className="personal-trainers-page__filters-content">
              <div>
                <Text
                  elementName="h2"
                  size={Text.sizes.large}
                  theme={Text.themes.medium}
                >
                  {filters.title}
                </Text>
              </div>
              <form className="personal-trainers-page__filters-inputs">
                <div>
                  <Select
                    {...filters.clubs.select}
                    onChange={handleClubChange}
                  />
                </div>
                <div>
                  <Select
                    {...filters.levels.select}
                    onChange={handleLevelChange}
                  />
                </div>
                <div>
                  <Select
                    {...filters.expertise.select}
                    onChange={handleExpertiseChange}
                  />
                </div>
                <div>
                  <TextField {...filters.search} icon={<Search18 />} naked />
                </div>
                <div>
                  <Button
                    size={Button.sizes.small}
                    text={filters.submit}
                    theme={Button.themes.complete}
                    type="submit"
                    wide
                  />
                </div>
              </form>
            </div>
          </ContentContainer>
        </div>
        <div className="personal-trainers-page__personal-trainers">
          <ContentContainer>
            <div className="personal-trainers-page__personal-trainers-content">
              {warning ? (
                <Message text={warning} type={Message.types.info} />
              ) : null}
              {results ? (
                <div className="personal-trainers-page__results">
                  <Text size={Text.sizes.basic} theme={Text.themes.medium}>
                    {results}
                  </Text>
                </div>
              ) : null}
              <PersonalTrainerCards {...personalTrainers} />
            </div>
          </ContentContainer>
        </div>
      </Layout>
    </div>
  );
};

export default PersonalTrainersPage;
